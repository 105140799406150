export const MODULES = [
  {
    id: 1,
    name: 'Equipment: materials',
    bg: 'rgb(255, 0, 38)',
  },
  {
    id: 2,
    name: 'Equipment: Manufacturing energy',
    bg: 'rgb(126, 0, 10)',
  },
  {
    id: 3,
    name: 'Shipping',
    bg: 'rgb(45, 45, 45)',
  },
  {
    id: 4,
    name: 'Operation',
    bg: 'rgb(189, 190, 191)',
  },
];
export const MODULES_COMPARE = [
  {
    id: 1,
    name: 'Equipment: Materials',
    bg: 'rgb(255, 0, 38)',
  },
  {
    id: 2,
    name: 'Equipment: Manufacturing energy',
    bg: 'rgb(126, 0, 10)',
  },
  {
    id: 3,
    name: 'Shipping',
    bg: 'rgb(45, 45, 45)',
  },
  {
    id: 4,
    name: 'Operation',
    bg: 'rgb(189, 190, 191)',
  },
];

export const CSS_NAME_PROJECT_MODULE = [
  {
    color: 'black',
  },
  {
    color: 'red',
  },
  {
    color: 'green',
  },
];

export const BACKGROUND_GRADIENT_COLOR = {
  equipmentMaterials: ['rgb(255, 0, 38)', 'rgb(255, 0, 38)'],
  equipmentManufacturingEnergy: ['rgb(126, 0, 10)', 'rgb(126, 0, 10)', 'rgb(126, 0, 10)'],
  shipping: ['rgb(45, 45, 45)', 'rgb(45, 45, 45)', 'rgb(45, 45, 45)', 'rgb(45, 45, 45)'],
  operation: ['rgb(189, 190, 191)', 'rgb(189, 190, 191)'],
};

export const TYPE_FACTOR = {
  GlobalWarmingPotential: 'Global Warming Potential',
  Acidification: 'Acidification',
  Eutrophication: 'Eutrophication',
  Freshwater: 'Freshwater Aquatic Ecotoxicity',
  Human: 'Human Toxicity',
  Marine: 'Marine Aquatic',
  Ozone: 'Ozone Layer Depletion',
  Photochemical: 'Photochemical Ozone Creation',
  Terrestric: 'Terrestric Ecotoxicity',
};

export const MapKeyFactor: any = {
  'Global Warming Potential': 'globalWarming',
  Acidification: 'acidification',
  Eutrophication: 'eutrophication',
  'Freshwater Aquatic Ecotoxicity': 'freshwater',
  'Human Toxicity': 'human',
  'Marine Aquatic': 'marine',
  'Ozone Layer Depletion': 'ozone',
  'Photochemical Ozone Creation': 'photochemical',
  'Terrestric Ecotoxicity': 'terrestric',
};

export const MAX_SIZE_SELECT_PROJECT = 2;
export const NAME_PROJECT_REPRESENT = ['Project A', 'Project B', 'Project C'];
export const NAME_MODULES_COMPARE = {
  material: 'Equipment: Materials',
  energy: 'Equipment: Manufacturing energy',
  shipping: 'Shipping',
  operation: 'Operation',
};
export const BACKGROUND_CHART = {
  equipmentMaterials: 'rgb(255, 0, 38)',
  equipmentManufacturingEnergy: 'rgb(126, 0, 10)',
  shipping: 'rgb(45, 45, 45)',
  operation: 'rgb(189, 190, 191)',
};
export const DEFAULT_SELECT_MODULE = [1, 2, 3, 4, 5];
export const DEFAULT_COLOR_DONUT_CHART = ['rgb(255, 0, 38)', 'rgb(126, 0, 10)', 'rgb(45, 45, 45)', 'rgb(189, 190, 191)'];
export const KEY_EQUIPMENT_MATERIALS = 1;
export const KEY_EQUIPMENT_ENERGY = 2;
export const KEY_OPERATION = 3;
export const KEY_SHIPPING = 4;
export const TYPE_MODULE_SCOPE = {
  EQUIPMENT: 'Equipment',
  SHIPPING: 'Shipping',
  OPERATION: 'Operational impact',
  SF6_ECONIQGAS: 'Gas leakage',
  SCOPE_3: 'Scope 3',
  SCOPE_2: 'Scope 2',
  SCOPE_1: 'Scope 1',
};
export const MODULES_COMPARE_TABLE = {
  material: 'Equipment: materials',
  energy: 'Equipment: manufacturing energy',
};
export const REPORT_BACKGROUND_GRADIENT_COLOR = {
  siteUsage: ['rgb(255, 0, 38)'],
  materials: ['rgb(189, 190, 191)'],
  travelling: ['rgb(45, 45, 45)'],
  machinery: ['rgb(126, 0, 10)'],
  shipping: ['rgb(108 108 108)'],
};
