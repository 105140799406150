export const PATH = {
  REFRESH_TOKEN: 'auth/refresh-token',
  USER: 'auth/user',
  ACTIVE_USER: 'auth/user/active',
  DEACTIVATE_USER: 'auth/user/deactivate',
  LOG_IN: 'auth/login',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  CHANGE_PASSWORD: 'auth/change-password',
  CHECK_TOKEN_RESET_PASSWORD: '/auth/check-token-reset-password',
  CHECK_MICROSOFT_ACCOUNT_EXIST: '/auth/check-microsoft-account-exist',
  LOG_OUT: 'auth/logout',
  PERMISSIONS: 'auth/permissions',
  PROJECT_SEARCH: '/eco-projects',
  SUB_PROJECT_SEARCH: '/eco-sub-projects',
  TOPICS_OPTION: '/eco-topics-options',
  HELP: '/eco-helps',
  PROJECT_IMPORT: '/eco-projects/import',
  PROJECT_DATA: '/eco-projects/data-project',
  PROJECT_DUPLICATE: '/eco-projects/duplicate',
  BUSINESS_UNIT: '/eco-business-units',
  BUSINESS_LINE: '/eco-business-lines',
  GLOBAL_PRODUCT: '/eco-global-product-groups',
  PURPOSE: '/eco-purpose-of-studies',
  LOCATION: 'eco-locations',
  VALUE_PER_KG_ELECTRIC_MIX_BY_LOCATION: 'eco-locations/get-value-per-kg-electric-mix-by-locations',
  CITY: 'eco-cities',
  REGION: 'eco-regions',
  SUB_PROJECT: '/eco-sub-projects/',
  SUB_PROJECT_DETAIL_BREADCRUMB: '/eco-sub-projects/get-breadcrumb',
  SUBPROJECT_DATA: 'eco-sub-projects/data-subproject',
  SUBPROJECT_DUPLICATE: '/eco-sub-projects/duplicate',
  PROJECT_DATA_ALL: '/eco-projects/list-all',
  PROJECT_DATA_RESULT: '/eco-results',
  DOWNLOAD_CSV_DATA_RESULT: '/eco-results/download-csv-results',
  DOWNLOAD_DATA_REPORT: '/eco-results/download-report',
  HANDLE_DOWNLOAD_DATA_REPORT: '/eco-results/handle-download-report',
  PROCESS_DOWNLOAD_DATA_REPORT: '/eco-results/process-download-report',
  CITY_WITH_LOCATION: 'eco-cities/get-city-with-location',
  PROJECT_EXPORT: '/eco-projects/export-project',
  DOWNLOAD_FILE: '/eco-attach-files/download',
  SALE_PROJECT_DATA: '/eco-sales-projects/data-project',
  SALE_PROJECT_DUPLICATE: '/eco-sales-projects/duplicate',
  ECO_ELECTRICITY_GIRD_MIXES: '/eco-electricity-grid-mixes',
  GET_COUNTRY_OF_OPERATION: '/eco-source-of-energies/country-of-operation',

  //Site Usage
  IMPACTS: 'eco-impactss',
  IMPACTS_TYPE: 'eco-impacts-types',
  SITE_USAGE: 'eco-site-usages',
  SITE_USAGE_IMPORT: '/eco-site-usages/import',
  SITE_USAGE_IMPORT_REQUIRED_FILES: '/eco-site-usages/import-site-usage-includes-files',

  // Material
  MATERIAL_LIST: '/eco-material-lists',
  MATERIAL_TYPE: '/eco-material-types',
  MATERIALS: '/eco-materials',
  MATERIALS_IMPORT: '/eco-materials/import',

  // Machinery
  MACHINERY: '/eco-machines',
  MACHINERY_MACHINE_TOOL_NAMES: '/eco-machinery-operations',
  MACHINERY_CATEGORIES: '/eco-machinery-operation-types',
  MACHINERY_SOURCES: '/eco-fuels',
  MACHINERY_IMPORT: '/eco-machines/import',

  // Shipping
  SHIPPINGS: '/eco-shippings',
  SHIPPING_ITEM: '/eco-shipping-items',
  SHIPPING_LEG: '/eco-shipping-legs',
  SHIPPING_TYPE: '/eco-shipping-types',
  SHIPPING_LIST: '/eco-shipping-lists',
  SHIPPINGS_IMPORT: '/eco-shippings/import',
  SHIPPINGS_IMPORT_REQUIRED_FILES: '/eco-shippings/import-shippings-includes-files',

  // Travelling
  TRAVELLING: '/eco-travellings',
  TRAVELLING_TYPE: '/eco-travelling-types',
  TRAVELLING_IMPORT: '/eco-travellings/import',

  // Data analyze
  ANALYZE: '/eco-analyze',
  ANALYZE_FILTER: '/eco-analyze/filter',

  // Data modifiers
  ECO_MODIFIERS: '/eco-modifiers',

  // Permission action
  ECO_PERMISSION_ACTION: '/eco-permission-actions',

  // Permission access subProject
  ECO_PERMISSION_SUB_PROJECT_ACCESS: '/eco-sub-project-accesses',

  // Permission access project
  ECO_PERMISSION_PROJECT_ACCESS: '/eco-project-accesses',

  // Tutorial
  ECO_TUTORIAL: '/eco-tutorials',

  // Project Solution
  ECO_PROJECT_SOLUTION: '/eco-sales-project-solutions',
  ECO_SALE_PROJECT: '/eco-sales-projects',
  ECO_PROJECT_CODE: '/eco-project-codes',
  ECO_SALE_OPERATION: '/eco-sales-operations',
  ECO_GPG_MASTER: '/eco-gpg-masters',

  // Sales list Shipping
  ECO_SALES_SHIPPING: '/eco-sales-shippings',
  ECO_SALES_TRANSPORT_MODE: '/eco-sales-shipping/get-value-transport-mode',

  // Equipment
  ECO_EQUIPMENT: '/eco-sales-equipments',
  ECO_SALE_PROJECT_ALL: '/eco-sales-projects/list-all',
  ECO_EQUIPMENT_MASTER: '/eco_equipment_masters',
  ECO_EQUIPMENT_SENSITIVITIES: '/eco-sensitivities-masters',

  // Sales list Summary
  ECO_SALES_SUMMARY: '/eco-sales-summary',

  // Sales project export
  ECO_SALES_PROJECT_EXPORT: '/eco-sales-projects/export-project',

  // Sales Result
  ECO_SALE_RESULT_SINGLE: '/eco-sales-result/single',
  ECO_SALE_RESULT_COMPARE: '/eco-sales-result/compare',
  ECO_SALE_RESULT_DOWNLOAD_CSV: '/eco-sales-result/export-csv',
  ECO_SALE_RESULT_DOWNLOAD_CSV_COMPARE: '/eco-sales-result/export-csv-compare',
  ECO_SALE_RESULT_DOWNLOAD_DATA_REPORT: '/eco-sales-result/download-report',
  ECO_SALE_RESULT_HANDLE_DOWNLOAD_DATA_REPORT_SINGLE: '/eco-sales-result/handle-download-report-single',
  ECO_SALE_RESULT_HANDLE_DOWNLOAD_DATA_REPORT_COMPARE: '/eco-sales-result/handle-download-report-comparison',

  // Sales Permission
  ECO_SALES_PERMISSION_PROJECT_ACCESS: '/eco-sales-project-accesses',
  ECO_SALES_PERMISSION_ACTION: '/eco-sales-permission-actions',
};
